@import "animations.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "overrides.css" layer(base);
@import "typography.css" layer(base);

@import "components.css" layer(components);
@import "utilities.css" layer(utilities);

