@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  -ms-overflow-style: none;
  --tw-text-opacity: 1;
  color: rgb(56 66 82 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

body #root {
  height: 100%;
  width: 100%;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.table-sticky {
  width: 100%;
  border-collapse: separate;
  text-align: left;
  border-spacing: 0;
}

.table-thead-row {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  letter-spacing: normal;
  padding: .75rem 1rem;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.2;
}

.table-th-sticky {
  z-index: 50;
  position: sticky;
}

.table-tbody-row {
  cursor: pointer;
}

.table-tbody-row:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.table-td {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  letter-spacing: normal;
  padding: .75rem 1rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

input[type="radio"] {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:before {
  content: "";
  width: 100%;
  height: 100%;
  background-clip: padding-box;
  border-width: 3px;
  border-color: #0000;
  border-radius: 50%;
  display: block;
}

input[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: rgb(98 85 236 / var(--tw-border-opacity));
}

input[type="radio"]:checked:before {
  --tw-bg-opacity: 1;
  background-color: rgb(98 85 236 / var(--tw-bg-opacity));
}

input[type="radio"]:disabled {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg preserveAspectRatio='xMidYMid meet' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"], [type="checkbox"]:indeterminate:hover {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

[type="checkbox"]:checked {
  border-width: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 10;
  position: absolute;
  right: 14px;
}

input[type="date"] {
  padding-right: 2rem;
}

@-moz-document url-prefix() {
  input[type="date"] {
    padding-right: .75rem;
  }

  input[type="date"] + svg {
    display: none;
  }
}

.application-card-border {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-style: solid;
  border-left-width: 3px;
}

div .application-card-border:not(:first-child), div a.reports-menu-item:not(:first-child) .application-card-border {
  border-top-width: 1px;
}

div a.reports-menu-item:first-child .application-card-border {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

div a.reports-menu-item:last-child .application-card-border {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

div .application-card-border:hover {
  --tw-border-opacity: 1;
  border-left-color: rgb(181 178 255 / var(--tw-border-opacity));
}

.application-card-selected-border {
  --tw-border-opacity: 1;
  border-left-color: rgb(132 128 244 / var(--tw-border-opacity));
}

fieldset[disabled] select {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(156 163 176 / var(--tw-text-opacity));
}

#tooltip[data-placement^="top"] > #arrow {
  bottom: -6px;
}

#tooltip[data-placement^="bottom"] > #arrow {
  top: -6px;
}

#tooltip[data-placement^="left"] > #arrow {
  right: -6px;
}

#tooltip[data-placement^="right"] > #arrow {
  left: -6px;
}

.tabs-negative-margin {
  margin-bottom: -2px;
}

.toggle {
  display: none;
}

.toggle, .toggle:after, .toggle:before, .toggle *, .toggle :after, .toggle :before, .toggle + .toggle-btn {
  box-sizing: border-box;
}

.toggle + .toggle-btn {
  height: 1.5rem;
  width: 2.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 176 / var(--tw-bg-opacity));
  border-radius: 1rem;
  padding: .25rem;
  transition: all .4s;
  display: block;
  position: relative;
}

.toggle:disabled + .toggle-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.toggle-lg + .toggle-btn-lg {
  height: 2rem;
  width: 3.5rem;
}

.toggle + .toggle-btn:after, .toggle + .toggle-btn:before {
  height: 100%;
  width: 50%;
  content: "";
  display: block;
  position: relative;
}

.toggle + .toggle-btn:after {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  transition: all .2s;
  left: 0;
}

.toggle + .toggle-btn:before {
  display: none;
}

.toggle:checked + .toggle-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(98 85 236 / var(--tw-bg-opacity));
}

.toggle:checked:disabled + .toggle-btn, .toggle:checked:disabled + .toggle-btn:after {
  opacity: .7;
}

.toggle:checked + .toggle-btn:after {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  left: 50%;
}

.email-template-preview-body, .email-template-body {
  letter-spacing: normal;
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

.email-template-preview-body p:empty, .email-template-body p:empty {
  height: 1.75rem;
  --tw-content: "";
  content: var(--tw-content);
  display: block;
}

.email-template-preview-body a, .email-template-body a {
  --tw-text-opacity: 1;
  color: rgb(98 85 236 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.email-template-preview-body span.variableSample, .email-template-preview-subject span.variableSample {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.email-template-body span.variableSample, .email-template-subject span.variableSample {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(98 85 236 / var(--tw-text-opacity));
  letter-spacing: normal;
  border-radius: 1.5rem;
  padding: .25rem .5rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

.email-template-body span.variableSample:before, .email-template-subject span.variableSample:before {
  content: url("data:image/svg+xml,%3csvg preserveAspectRatio='xMidYMid meet' width='16' height='16' viewBox='0 0 16 16' fill='%236255EC' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.3333 8.49916L10.6193 13.2132L9.67663 12.2705L13.448 8.49916L9.67663 4.72782L10.6193 3.78516L15.3333 8.49916ZM2.55196 8.49916L6.32329 12.2705L5.38063 13.2132L0.666626 8.49916L5.38063 3.78516L6.32329 4.72782L2.55196 8.49916Z' fill='%236255EC' /%3e%3c/svg%3e");
  vertical-align: middle;
  margin-right: .25rem;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-\[4\.4rem\] {
  bottom: 4.4rem;
}

.bottom-\[4\.5rem\] {
  bottom: 4.5rem;
}

.left-0 {
  left: 0;
}

.left-1\/4 {
  left: 25%;
}

.left-4 {
  left: 1rem;
}

.left-6 {
  left: 1.5rem;
}

.left-8 {
  left: 2rem;
}

.left-\[-0\.5rem\] {
  left: -.5rem;
}

.left-\[100\%\] {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.right-\[100\%\] {
  right: 100%;
}

.right-\[175\%\] {
  right: 175%;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.top-\[3\.75rem\] {
  top: 3.75rem;
}

.top-\[4\.5rem\] {
  top: 4.5rem;
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[150\] {
  z-index: 150;
}

.z-\[200\] {
  z-index: 200;
}

.z-\[55\] {
  z-index: 55;
}

.z-\[99\] {
  z-index: 99;
}

.col-start-1 {
  grid-column-start: 1;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-\[4\.5px\] {
  margin-top: 4.5px;
  margin-bottom: 4.5px;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-px {
  margin-left: 1px;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[0\.625rem\] {
  height: .625rem;
}

.h-\[0\.875rem\] {
  height: .875rem;
}

.h-\[1\.125rem\] {
  height: 1.125rem;
}

.h-\[10rem\] {
  height: 10rem;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[21rem\] {
  height: 21rem;
}

.h-\[23\.25rem\] {
  height: 23.25rem;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[51\.5rem\] {
  height: 51.5rem;
}

.h-\[6\.25rem\] {
  height: 6.25rem;
}

.h-\[7\.5rem\] {
  height: 7.5rem;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[95\%\] {
  height: 95%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-12\/13 {
  max-height: 92%;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[95\%\] {
  max-height: 95%;
}

.max-h-none {
  max-height: none;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-application-panel-tab {
  min-height: 35.75rem;
}

.min-h-empty-table {
  min-height: 44rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-128 {
  width: 32rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/5 {
  width: 40%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-\[0\.875rem\] {
  width: .875rem;
}

.w-\[1\.125rem\] {
  width: 1.125rem;
}

.w-\[10rem\] {
  width: 10rem;
}

.w-\[11\.25rem\] {
  width: 11.25rem;
}

.w-\[12\.5rem\] {
  width: 12.5rem;
}

.w-\[12\.62\%\] {
  width: 12.62%;
}

.w-\[12rem\] {
  width: 12rem;
}

.w-\[13\.125rem\] {
  width: 13.125rem;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[15\.625rem\] {
  width: 15.625rem;
}

.w-\[17\.625rem\] {
  width: 17.625rem;
}

.w-\[176px\] {
  width: 176px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[20\.77\%\] {
  width: 20.77%;
}

.w-\[23rem\] {
  width: 23rem;
}

.w-\[25rem\] {
  width: 25rem;
}

.w-\[27rem\] {
  width: 27rem;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[30rem\] {
  width: 30rem;
}

.w-\[34rem\] {
  width: 34rem;
}

.w-\[36rem\] {
  width: 36rem;
}

.w-\[37\.5rem\] {
  width: 37.5rem;
}

.w-\[4\.32\%\] {
  width: 4.32%;
}

.w-\[46\.875rem\] {
  width: 46.875rem;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50rem\] {
  width: 50rem;
}

.w-\[531px\] {
  width: 531px;
}

.w-\[56\.625rem\] {
  width: 56.625rem;
}

.w-\[56rem\] {
  width: 56rem;
}

.w-\[59rem\] {
  width: 59rem;
}

.w-\[6\.25rem\] {
  width: 6.25rem;
}

.w-\[7\.5rem\] {
  width: 7.5rem;
}

.w-\[7\.75rem\] {
  width: 7.75rem;
}

.w-\[8\.8rem\] {
  width: 8.8rem;
}

.w-\[9\.25rem\] {
  width: 9.25rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-side-menu {
  width: 188px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-68 {
  min-width: 17rem;
}

.min-w-application-detail-panel {
  min-width: 1006px;
}

.min-w-application-panel {
  min-width: 1064px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-144 {
  max-width: 36rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[0\.8\] {
  flex: .8;
}

.flex-\[1\.2\] {
  flex: 1.2;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.-translate-x-0 {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-items-center {
  justify-items: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-0\.5 {
  column-gap: .125rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-\[0\.53125rem\] {
  row-gap: .53125rem;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.125rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[2\.5rem\] {
  border-radius: 2.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-critical-default {
  --tw-border-opacity: 1;
  border-color: rgb(248 114 114 / var(--tw-border-opacity));
}

.border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-emphasized {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-on-primary {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-primary-default {
  --tw-border-opacity: 1;
  border-color: rgb(132 128 244 / var(--tw-border-opacity));
}

.border-primary-emphasized {
  --tw-border-opacity: 1;
  border-color: rgb(98 85 236 / var(--tw-border-opacity));
}

.border-subdued {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-warning-default {
  --tw-border-opacity: 1;
  border-color: rgb(251 189 35 / var(--tw-border-opacity));
}

.border-b-default {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-b-subdued {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-default {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-t-subdued {
  --tw-border-opacity: 1;
  border-top-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.bg-actions-critical-default {
  --tw-bg-opacity: 1;
  background-color: rgb(239 67 67 / var(--tw-bg-opacity));
}

.bg-actions-critical-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-actions-primary-default {
  --tw-bg-opacity: 1;
  background-color: rgb(98 85 236 / var(--tw-bg-opacity));
}

.bg-actions-primary-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-critical-default {
  --tw-bg-opacity: 1;
  background-color: rgb(239 67 67 / var(--tw-bg-opacity));
}

.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-icons-critical-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(220 40 40 / var(--tw-bg-opacity));
}

.bg-icons-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 176 / var(--tw-bg-opacity));
}

.bg-icons-primary-default {
  --tw-bg-opacity: 1;
  background-color: rgb(98 85 236 / var(--tw-bg-opacity));
}

.bg-icons-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-icons-success-default {
  --tw-bg-opacity: 1;
  background-color: rgb(16 183 127 / var(--tw-bg-opacity));
}

.bg-icons-success-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(5 148 103 / var(--tw-bg-opacity));
}

.bg-icons-warning-default {
  --tw-bg-opacity: 1;
  background-color: rgb(245 159 10 / var(--tw-bg-opacity));
}

.bg-overlay-default {
  background-color: #0006;
}

.bg-success-default {
  --tw-bg-opacity: 1;
  background-color: rgb(16 183 127 / var(--tw-bg-opacity));
}

.bg-surface-critical-default {
  --tw-bg-opacity: 1;
  background-color: rgb(254 225 225 / var(--tw-bg-opacity));
}

.bg-surface-critical-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(254 241 241 / var(--tw-bg-opacity));
}

.bg-surface-dark-default {
  --tw-bg-opacity: 1;
  background-color: rgb(56 66 82 / var(--tw-bg-opacity));
}

.bg-surface-dark-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-surface-dark-selected {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-surface-dark-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-surface-default {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-surface-disabled, .bg-surface-emphasized, .bg-surface-hovered-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-surface-info-default {
  --tw-bg-opacity: 1;
  background-color: rgb(220 235 254 / var(--tw-bg-opacity));
}

.bg-surface-info-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(240 246 255 / var(--tw-bg-opacity));
}

.bg-surface-primary-default {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 254 / var(--tw-bg-opacity));
}

.bg-surface-primary-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(213 212 252 / var(--tw-bg-opacity));
}

.bg-surface-primary-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity));
}

.bg-surface-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-surface-success-default {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-surface-success-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(237 253 245 / var(--tw-bg-opacity));
}

.bg-surface-warning-default {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 200 / var(--tw-bg-opacity));
}

.bg-surface-warning-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-warning-default {
  --tw-bg-opacity: 1;
  background-color: rgb(245 159 10 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-text-top {
  vertical-align: text-top;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.capitalize {
  text-transform: capitalize;
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-3 {
  line-height: .75rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-critical-default {
  --tw-text-opacity: 1;
  color: rgb(239 67 67 / var(--tw-text-opacity));
}

.text-critical-emphasized {
  --tw-text-opacity: 1;
  color: rgb(220 40 40 / var(--tw-text-opacity));
}

.text-default {
  --tw-text-opacity: 1;
  color: rgb(56 66 82 / var(--tw-text-opacity));
}

.text-disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 176 / var(--tw-text-opacity));
}

.text-emphasized {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-icons-critical-default {
  --tw-text-opacity: 1;
  color: rgb(239 67 67 / var(--tw-text-opacity));
}

.text-icons-critical-emphasized {
  --tw-text-opacity: 1;
  color: rgb(220 40 40 / var(--tw-text-opacity));
}

.text-icons-default {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-icons-disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 176 / var(--tw-text-opacity));
}

.text-icons-emphasized {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-icons-info-default {
  --tw-text-opacity: 1;
  color: rgb(60 131 246 / var(--tw-text-opacity));
}

.text-icons-info-emphasized {
  --tw-text-opacity: 1;
  color: rgb(36 99 235 / var(--tw-text-opacity));
}

.text-icons-on-destructive, .text-icons-on-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-icons-primary-default {
  --tw-text-opacity: 1;
  color: rgb(98 85 236 / var(--tw-text-opacity));
}

.text-icons-subdued {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-icons-success-default {
  --tw-text-opacity: 1;
  color: rgb(16 183 127 / var(--tw-text-opacity));
}

.text-icons-success-emphasized {
  --tw-text-opacity: 1;
  color: rgb(5 148 103 / var(--tw-text-opacity));
}

.text-icons-warning-default {
  --tw-text-opacity: 1;
  color: rgb(245 159 10 / var(--tw-text-opacity));
}

.text-icons-warning-emphasized {
  --tw-text-opacity: 1;
  color: rgb(219 119 6 / var(--tw-text-opacity));
}

.text-info-default {
  --tw-text-opacity: 1;
  color: rgb(60 131 246 / var(--tw-text-opacity));
}

.text-info-emphasized {
  --tw-text-opacity: 1;
  color: rgb(36 99 235 / var(--tw-text-opacity));
}

.text-on-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 176 / var(--tw-text-opacity));
}

.text-primary-default {
  --tw-text-opacity: 1;
  color: rgb(98 85 236 / var(--tw-text-opacity));
}

.text-primary-emphasized {
  --tw-text-opacity: 1;
  color: rgb(69 56 214 / var(--tw-text-opacity));
}

.text-subdued {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-success-default {
  --tw-text-opacity: 1;
  color: rgb(16 183 127 / var(--tw-text-opacity));
}

.text-success-emphasized {
  --tw-text-opacity: 1;
  color: rgb(5 148 103 / var(--tw-text-opacity));
}

.text-warning-default {
  --tw-text-opacity: 1;
  color: rgb(245 159 10 / var(--tw-text-opacity));
}

.text-warning-emphasized {
  --tw-text-opacity: 1;
  color: rgb(219 119 6 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-default::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 176 / var(--tw-placeholder-opacity));
}

.opacity-100 {
  opacity: 1;
}

.opacity-60 {
  opacity: .6;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-base {
  --tw-shadow: 0px 0px 1px #00000029, 0px 1px 6px #0000000f;
  --tw-shadow-colored: 0px 0px 1px var(--tw-shadow-color), 0px 1px 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 4px 20px 0 #00000026, 0 0 3px 0 #0000001a;
  --tw-shadow-colored: 0 4px 20px 0 var(--tw-shadow-color), 0 0 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 2px 10px 0 #0000001a, 0 0 2px 0 #00000026;
  --tw-shadow-colored: 0 2px 10px 0 var(--tw-shadow-color), 0 0 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-modal-drop-shadow {
  --tw-shadow: 0px 4px 34px #00000026;
  --tw-shadow-colored: 0px 4px 34px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 8px 40px 0 #0003, 0 0 4px 0 #0000001a;
  --tw-shadow-colored: 0 8px 40px 0 var(--tw-shadow-color), 0 0 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.drop-shadow-base {
  --tw-drop-shadow: drop-shadow(0px 0px 1px #00000029) drop-shadow(0px 1px 6px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.font-h1-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-h2-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-h3-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-h4-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-h4-medium {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-base-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-base-semi-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-base-medium, .font-paragraph-base-button-medium, .font-paragraph-base-link-medium {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-base-regular {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-small-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-small-semi-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-small-link-medium, .font-paragraph-small-button-medium, .font-paragraph-small-medium {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-small-link-regular, .font-paragraph-small-regular {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-xsmall-semi-bold {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-xsmall-link-medium, .font-paragraph-xsmall-medium {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.2;
}

.font-paragraph-xsmall-link-regular, .font-paragraph-xsmall-regular {
  letter-spacing: normal;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

@media not all and (min-resolution: .001dpcm) {
  @supports ((-webkit-appearance: none)) and (stroke-color: transparent) {
    input[type="date"] {
      padding-right: 3rem;
    }
  }
}

.marker\:text-critical-default ::marker {
  color: #ef4343;
}

.marker\:text-marker-subdued ::marker {
  color: #e5e7eb;
}

.marker\:text-success-default ::marker {
  color: #10b77f;
}

.marker\:text-critical-default::marker {
  color: #ef4343;
}

.marker\:text-marker-subdued::marker {
  color: #e5e7eb;
}

.marker\:text-success-default::marker {
  color: #10b77f;
}

.before\:visible:before {
  content: var(--tw-content);
  visibility: visible;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:h-3:before {
  content: var(--tw-content);
  height: .75rem;
}

.before\:w-3:before {
  content: var(--tw-content);
  width: .75rem;
}

.before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-surface-dark-default:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(56 66 82 / var(--tw-bg-opacity));
}

.before\:bg-surface-dark-emphasized:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.first\:mt-4:first-child {
  margin-top: 1rem;
}

.first\:rounded-t:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.last\:rounded-b:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.checked\:border-transparent:checked {
  border-color: #0000;
}

.checked\:bg-actions-primary-default:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(98 85 236 / var(--tw-bg-opacity));
}

.checked\:bg-actions-primary-disabled:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:border-subdued:hover {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.hover\:bg-surface-emphasized:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-surface-hovered-default:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-surface-hovered-emphasized:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-surface-primary-default:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 232 254 / var(--tw-bg-opacity));
}

.hover\:text-icons-emphasized:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-primary-emphasized:hover {
  --tw-text-opacity: 1;
  color: rgb(69 56 214 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-primary-default:focus {
  --tw-border-opacity: 1;
  border-color: rgb(132 128 244 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:rounded {
  border-radius: .25rem;
}

.group:hover .group-hover\:bg-actions-critical-hovered {
  --tw-bg-opacity: 1;
  background-color: rgb(220 40 40 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-actions-primary-hovered {
  --tw-bg-opacity: 1;
  background-color: rgb(69 56 214 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-surface-critical-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(254 241 241 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-surface-hovered-default {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-surface-hovered-emphasized {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-surface-primary-subdued {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-critical-emphasized, .group:hover .group-hover\:text-icons-critical-emphasized {
  --tw-text-opacity: 1;
  color: rgb(220 40 40 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-icons-primary-emphasized {
  --tw-text-opacity: 1;
  color: rgb(69 56 214 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-primary-default {
  --tw-text-opacity: 1;
  color: rgb(98 85 236 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-primary-emphasized {
  --tw-text-opacity: 1;
  color: rgb(69 56 214 / var(--tw-text-opacity));
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:w-application-child-panel {
    width: 1000px;
  }

  .lg\:w-application-detail-panel {
    width: 1006px;
  }

  .lg\:w-application-grandchild-panel {
    width: 888px;
  }

  .lg\:w-application-panel {
    width: 1064px;
  }

  .lg\:w-job-side-panel {
    width: 368px;
  }

  .lg\:w-panel {
    width: 976px;
  }

  .lg\:grid-cols-\[auto_1fr\] {
    grid-template-columns: auto 1fr;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }
}

/*# sourceMappingURL=index.54c2410c.css.map */
